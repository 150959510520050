<template>
	<div>
		<Header ref="Header"/>
		<img :src="menuPic" class="Big-title"/>
		<div class="container">
			<div class="personal-list row">
				<div class="personal-list-left">
					<div class="index-sort-tiltle personal-sort-tiltle">个人中心</div>
					<div class="index-sort-list">
						<div v-for="(item,index) in nav">
							<div @click="sortSwitch(index)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
								{{item.name}}
							</div>
							<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
						</div>
					</div>
				</div>
				<div class="personal-list-right">
					<div class="personal-right-collect">
						<div class="personal-collect-list">
							<div v-for="(item,index) in collect" :key="index">
								<div class="personal-collect-item row">
									<img @click="pageTo(item.placeId)" class="personal-collect-pic jump" :src="item.placePicture"/>
									<div class="personal-collect-item-right row">
										<div class="personal-collect-midle">
											<div @click="pageTo(item.placeId)" class="personal-collect-name jump">【{{item.placeCity}}】{{item.placeName}}</div>
											<div class="personal-collect-place row">
												<img class="personal-collect-loc" src="../../public/images/place_01.png">
												<div class="personal-collect-address">{{item.placeAddress}}</div>
											</div>
											<div class="personal-collect-lable flex">
												<div v-for="(item,index) in item.placeMark" :key="index">
													<div class="personal-collect-lable-item">{{item}}</div>
												</div>
											</div>
										</div>
										<div class="personal-collect-right">
											<img v-if="item.status==1" @click="cancel(index,item.placeId)"
											class="personal-collect-aixin" src="../../public/images/collect_02.png">
											<img v-if="item.status==0" @click="add(index,item.placeId)"
											class="personal-collect-aixin" src="../../public/images/collect_01.png">
											<!-- <div class="personal-collect-price row">
												<div class="personal-collect-num">￥{{item.placePrice}}</div>
												<div class="personal-collect-qi">{{item.placePriceUnit}}</div>
											</div> -->
											<div class="personal-collect-btn jump" @click="pageTo(item.placeId)">查看详情</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="collect.length==0" class="mypublish-tips">
								暂无数据哦~
							</div>
							<el-pagination
								style="margin-top: 50px;"
								class="bar-switch"
								background
								layout="prev, pager, next, jumper, total"
								:page-size="postData.pageSize"
								:total="Total"
								@current-change="choosePage">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import  Header from '@/components/header.vue';
	import { mapState } from 'vuex';
	export default {
		components:{Header},
		data() {
			return {
				Total:0,
				menuPic:'',
				sortIndex:4,
				postData:{
					pageNum:1,
					pageSize:10,
				},
				nav:[
					{name:'个人信息',href:'personal'},
					{name:'发布需求',href:'publish'},
					{name:'我的订单',href:'myOrder'},
					{name:'我的发布',href:'myPublish'},
					{name:'我的收藏',href:'myCollect'},
					{name:'我的消息',href:'myNews'},
					{name:'意见反馈',href:'feedback'},
				],
				collect:[],
			}
		},
		computed:{
		...mapState(['userInfo']),
		},
		mounted() {
			this.$refs.Header.setIndex(-1);
			// 获取菜单图片
			this.$api.getMenuPic('发布需求').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			// 获取我的收藏
			this.getMyFavorites();
		},
		methods: {
			sortSwitch(index){
				this.sortIndex=index;
				this.$router.push({path:this.nav[index].href});
			},
			pageTo(placeId){
				this.$router.push({
					path:'introduction',
					query:{
						id:placeId
					}
				});
			},
			// 取消收藏
			cancel(index,placeId){
				this.$api.removeFavorites(placeId).then(res=>{
					console.log(res,'取消返回');
					if(res){
						console.log(index)
						this.collect.splice(index,1);
						console.log(this.collect)
					}
				})
			},
			add(index,placeId){
				this.$api.addFavorites(placeId).then(res=>{
					console.log(res,'收藏返回');
					if(res){
						this.collect[index].status=1;
					}
				})
			},
			// 选择分页
			choosePage(num){
				this.postData.pageNum=num;
				this.getMyFavorites();
			},
			// 获取我的收藏
			getMyFavorites(){
				this.$api.myFavorites(this.postData).then(res=>{
					for(let i=0; i<res.rows.length;i++){
						if(res.rows[i].placeMark!=null){
							res.rows[i].placeMark=res.rows[i].placeMark.split(',')
						}
						res.rows[i].status=1;
					}
					console.log(res)
					this.collect=res.rows;
					this.Total=parseInt(res.total);
				})
			}
		},
	};
</script>
<style scoped>
</style>